import { Link } from "react-router-dom";

// Import Custom Hooks
import useAxios from "../../../CustomHooks/useAxios/useAxios";
import usePreventRouterLinks from "../../../CustomHooks/usePreventRouterLinks/usePreventRouterLinks";

// Main Navbar Logo Sass File
import "./Logo.scss";

// Navbar Logo Component
const NavbarLogo = () => {
  // Custom Hooks
  const { preventRouterLinks } = usePreventRouterLinks(
    `${process.env.PUBLIC_URL}/`
  );

  // Fetch data
  const {
    data: { image = "", text = "" },
  } = useAxios(`${process.env.PUBLIC_URL}/apis/logo.json`, []);

  return (
    <Link
      to={`${process.env.PUBLIC_URL}/`}
      onClick={preventRouterLinks}
      className="logo"
    >
      {image && (
        <img src={image} alt="Logo" draggable="false" className="logo-image" />
      )}

      {text && <span className="logo-text">{text}</span>}
    </Link>
  );
};

export default NavbarLogo;
