export const SIDEBAR_OPEN = "SIDEBAR_OPEN";
export const SIDEBAR_CLOSE = "SIDEBAR_CLOSE";
export const GET_SPORTS_BEGIN = "GET_SPORTS_BEGIN";
export const GET_SPORTS_SUCCESS = "GET_SPORTS_SUCCESS";
export const GET_SPORTS_ERROR = "GET_SPORTS_ERROR";
export const GET_SINGLE_SPORT_BEGIN = "GET_SINGLE_SPORT_BEGIN";
export const GET_SINGLE_SPORT_SUCCESS = "GET_SINGLE_SPORT_SUCCESS";
export const GET_SINGLE_SPORT_ERROR = "GET_SINGLE_SPORT_ERROR";
export const LOAD_SPORTS = "LOAD_SPORTS";
export const SET_GRIDVIEW = "SET_GRIDVIEW";
export const SET_LISTVIEW = "SET_LISTVIEW";
export const UPDATE_SORT = "UPDATE_SORT";
export const SORT_SPORTS = "SORT_SPORTS";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const FILTER_SPORTS = "FILTER_SPORTS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const TOGGLE_CART_ITEM_AMOUNT = "TOGGLE_CART_ITEM_AMOUNT";
export const CLEAR_CART = "CLEAR_CART";
export const COUNT_CART_TOTALS = "COUNT_CART_TOTALS";
